<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title>Users</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">Users</ion-title>
        </ion-toolbar>
      </ion-header>

      <div id="container">
        <ion-list>
          <ion-list-header>All Users</ion-list-header>

          <ion-item
            class="hydrated"
            detail="false"
            v-for="user in listOfUsers"
            :key="user.uid"
            @click="showUserActionSheet(user)"
          >
            <ion-label
              ><ion-icon
                v-if="user.permissions && user.permissions.admin"
                slot="start"
                :icon="lockClosedOutline"
              ></ion-icon>
              {{ user.displayName }} ({{ user.email }})</ion-label
            >
            <ion-icon slot="end" :icon="ellipsisVerticalSharp"></ion-icon>
          </ion-item>
        </ion-list>

        <ion-buttons class="bottom">
          <ion-button :disabled="!hasPreviousPage" @click="previousPage"
            ><ion-icon :icon="chevronBackOutline"
          /></ion-button>
          <ion-button :disabled="!hasNextPage" @click="nextPage"
            ><ion-icon :icon="chevronForwardOutline"
          /></ion-button>
        </ion-buttons>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import firebase from "firebase/app";
require("firebase/functions");

const listUsersFunction = firebase.functions().httpsCallable("listUsers");
const deleteUserFunction = firebase.functions().httpsCallable("deleteUser");
const setUserAsAdminFunction = firebase
  .functions()
  .httpsCallable("setUserAsAdmin");
const unsetUserAsAdminFunction = firebase
  .functions()
  .httpsCallable("unsetUserAsAdmin");

export interface User {
  disabled: boolean;
  displayName?: string;
  email: string;
  emailVerified: boolean;
  metadata: {
    lastSignInTime: string;
    creationTime: string;
  };
  permissions?: { admin: boolean };
  photoURL: string;
  uid: string;
}

import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonItem,
  IonList,
  IonLabel,
  IonButton,
  IonIcon,
  IonListHeader,
  actionSheetController,
} from "@ionic/vue";

import {
  chevronForwardOutline,
  chevronBackOutline,
  lockClosedOutline,
  chevronForwardSharp,
  chevronBackSharp,
  lockClosedSharp,
  ellipsisVerticalSharp,
  trashOutline,
  closeOutline,
  bulbOutline,
  lockOpenOutline,
} from "ionicons/icons";

import { ref } from "vue";
import { defineComponent } from "vue";
import router from "@/main";

export default defineComponent({
  name: "UsersList",
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonItem,
    IonList,
    IonLabel,
    IonButton,
    IonIcon,
    IonListHeader,
  },
  data() {
    return {
      currentPage: 1,
    };
  },
  setup() {
    const listOfUsers = ref<Array<User>>([]);
    const pageToken = ref<string>("");

    // eslint-disable-next-line
    firebase
      .auth()
      .currentUser!.getIdToken()
      .then((token) => {
        listUsersFunction({ token: token }).then((results) => {
          results.data.users?.forEach((e: User) => {
            listOfUsers.value.push(e);
          });
        });
      });

    const showUserActionSheet = async (user: User) => {
      const actionSheet = await actionSheetController.create({
        header: "" + user.displayName + " (" + user.email + ")",
        cssClass: "action-sheet",
        buttons: [
          {
            text: user.permissions?.admin ? "Remove Admin Role" : "Make Admin",
            icon: user.permissions?.admin ? lockOpenOutline : lockClosedOutline,
            handler: () => {
              console.log("Here:" + user.permissions?.admin);
              if (user.permissions?.admin) {
                //eslint-disable-next-line
                firebase
                  .auth()
                  .currentUser!.getIdToken()
                  .then(function (token) {
                    unsetUserAsAdminFunction({
                      uid: user.uid,
                      token: token,
                    }).then((e) => {
                      console.log(e);
                    });
                  });
              } else {
                //eslint-disable-next-line
                firebase
                  .auth()
                  .currentUser!.getIdToken()
                  .then(function (token) {
                    setUserAsAdminFunction({ uid: user.uid, token: token });
                  });
              }
            },
          },
          {
            text: "View Submissions",
            icon: bulbOutline,
            handler() {
              router.router.push("/submissions/" + user.uid);
            },
          },
          {
            text: "Delete",
            role: "destructive",
            icon: trashOutline,
            handler: () => {
              //eslint-disable-next-line
              firebase
                .auth()
                .currentUser!.getIdToken()
                .then(function (token) {
                  deleteUserFunction({ uid: user.uid, token: token });
                });
            },
          },
          {
            text: "Cancel",
            icon: closeOutline,
            role: "cancel",
            handler: () => {
              // Nothing to do, action sheet is automatically closed
            },
          },
        ],
      });
      await actionSheet.present();
    };

    return {
      listOfUsers,
      pageToken,
      chevronForwardOutline,
      chevronForwardSharp,
      chevronBackOutline,
      chevronBackSharp,
      lockClosedOutline,
      lockClosedSharp,
      ellipsisVerticalSharp,
      showUserActionSheet,
    };
  },
  computed: {
    hasPreviousPage(): boolean {
      return this.currentPage > 1;
    },
    hasNextPage(): boolean {
      return this.pageToken.length > 0;
    },
  },
  methods: {
    previousPage(): boolean {
      return true;
    },
    nextPage(): boolean {
      return true;
    },
  },
});
</script>

<style scoped>
#container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

ion-list {
  flex: 1;
}
/* #container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

#container a {
  text-decoration: none;
} */
ion-buttons {
  align-items: center;
  justify-content: center;
  margin-top: 1em;
  margin-bottom: 2em;
}
ion-button {
  --background: #fff !important;
  --border-style: solid !important;
  --border-width: 1px !important;
  --border-color: #ddd !important;
}
ion-icon {
  color: #000;
  /* --color: #000; */
}
</style>