
import firebase from "firebase/app";
require("firebase/functions");

const listUsersFunction = firebase.functions().httpsCallable("listUsers");
const deleteUserFunction = firebase.functions().httpsCallable("deleteUser");
const setUserAsAdminFunction = firebase
  .functions()
  .httpsCallable("setUserAsAdmin");
const unsetUserAsAdminFunction = firebase
  .functions()
  .httpsCallable("unsetUserAsAdmin");

export interface User {
  disabled: boolean;
  displayName?: string;
  email: string;
  emailVerified: boolean;
  metadata: {
    lastSignInTime: string;
    creationTime: string;
  };
  permissions?: { admin: boolean };
  photoURL: string;
  uid: string;
}

import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonItem,
  IonList,
  IonLabel,
  IonButton,
  IonIcon,
  IonListHeader,
  actionSheetController,
} from "@ionic/vue";

import {
  chevronForwardOutline,
  chevronBackOutline,
  lockClosedOutline,
  chevronForwardSharp,
  chevronBackSharp,
  lockClosedSharp,
  ellipsisVerticalSharp,
  trashOutline,
  closeOutline,
  bulbOutline,
  lockOpenOutline,
} from "ionicons/icons";

import { ref } from "vue";
import { defineComponent } from "vue";
import router from "@/main";

export default defineComponent({
  name: "UsersList",
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonItem,
    IonList,
    IonLabel,
    IonButton,
    IonIcon,
    IonListHeader,
  },
  data() {
    return {
      currentPage: 1,
    };
  },
  setup() {
    const listOfUsers = ref<Array<User>>([]);
    const pageToken = ref<string>("");

    // eslint-disable-next-line
    firebase
      .auth()
      .currentUser!.getIdToken()
      .then((token) => {
        listUsersFunction({ token: token }).then((results) => {
          results.data.users?.forEach((e: User) => {
            listOfUsers.value.push(e);
          });
        });
      });

    const showUserActionSheet = async (user: User) => {
      const actionSheet = await actionSheetController.create({
        header: "" + user.displayName + " (" + user.email + ")",
        cssClass: "action-sheet",
        buttons: [
          {
            text: user.permissions?.admin ? "Remove Admin Role" : "Make Admin",
            icon: user.permissions?.admin ? lockOpenOutline : lockClosedOutline,
            handler: () => {
              console.log("Here:" + user.permissions?.admin);
              if (user.permissions?.admin) {
                //eslint-disable-next-line
                firebase
                  .auth()
                  .currentUser!.getIdToken()
                  .then(function (token) {
                    unsetUserAsAdminFunction({
                      uid: user.uid,
                      token: token,
                    }).then((e) => {
                      console.log(e);
                    });
                  });
              } else {
                //eslint-disable-next-line
                firebase
                  .auth()
                  .currentUser!.getIdToken()
                  .then(function (token) {
                    setUserAsAdminFunction({ uid: user.uid, token: token });
                  });
              }
            },
          },
          {
            text: "View Submissions",
            icon: bulbOutline,
            handler() {
              router.router.push("/submissions/" + user.uid);
            },
          },
          {
            text: "Delete",
            role: "destructive",
            icon: trashOutline,
            handler: () => {
              //eslint-disable-next-line
              firebase
                .auth()
                .currentUser!.getIdToken()
                .then(function (token) {
                  deleteUserFunction({ uid: user.uid, token: token });
                });
            },
          },
          {
            text: "Cancel",
            icon: closeOutline,
            role: "cancel",
            handler: () => {
              // Nothing to do, action sheet is automatically closed
            },
          },
        ],
      });
      await actionSheet.present();
    };

    return {
      listOfUsers,
      pageToken,
      chevronForwardOutline,
      chevronForwardSharp,
      chevronBackOutline,
      chevronBackSharp,
      lockClosedOutline,
      lockClosedSharp,
      ellipsisVerticalSharp,
      showUserActionSheet,
    };
  },
  computed: {
    hasPreviousPage(): boolean {
      return this.currentPage > 1;
    },
    hasNextPage(): boolean {
      return this.pageToken.length > 0;
    },
  },
  methods: {
    previousPage(): boolean {
      return true;
    },
    nextPage(): boolean {
      return true;
    },
  },
});
